<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Informatii utile">
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <!-- Latest Customers -->
        <b-col xl="6">
          <base-block title="Info furnizor" header-bg content-full>
            <h6>Program de lucru</h6>
            <b-row>
              <b-col xl="12">
                <b-row class="font-size-sm">
                  <b-col sm="3" class="text-primary">
                    Luni - Vineri:
                  </b-col>
                  <b-col sm="9">
                    9:30-18:00
                  </b-col>
                </b-row>
              </b-col>
              <b-col xl="12">
                <p class="mt-3">De luni pana vineri, comenzile primite pana la ora 16:30 se expediaza in aceeasi zi.<br/>
                  Comenzile primite in weekend vor fi expediate in ziua de luni.
                </p>
              </b-col>
            </b-row>
            <h6 class="mt-3">Departament vanzari</h6>
            <b-row>
              <b-col xl="12">
                <b-row class="font-size-sm">
                  <b-col sm="3" class="text-primary">
                    Adresa:
                  </b-col>
                  <b-col sm="9">
                    Str. Leliceni FN, Miercurea Ciuc, Harghita
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Telefon:
                  </b-col>
                  <b-col sm="9">
                    0728-302443, 0744-472280, 0366-362827
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    WhatsApp:
                  </b-col>
                  <b-col sm="9">
                    0744-472280
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Skype:
                  </b-col>
                  <b-col sm="9">
                    laptopservice.ro
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Email:
                  </b-col>
                  <b-col sm="9">
                    comenzi@lazarcomputers.ro
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <h6 class="mt-3">Depozit</h6>
            <b-row>
              <b-col xl="12">
                <b-row class="font-size-sm">
                  <b-col sm="3" class="text-primary">
                    Adresa:
                  </b-col>
                  <b-col sm="9">
                    Str. Leliceni FN, Miercurea Ciuc, Harghita
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Telefon:
                  </b-col>
                  <b-col sm="9">
                    0728-302443
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Email:
                  </b-col>
                  <b-col sm="9">
                    logistica@lazarcomputers.ro
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <h6 class="mt-3">Service & Garantie (RMA)</h6>
            <b-row>
              <b-col xl="12">
                <b-row class="font-size-sm">
                  <b-col sm="3" class="text-primary">
                    Adresa:
                  </b-col>
                  <b-col sm="9">
                    Str. Leliceni FN, Miercurea Ciuc, Harghita
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Telefon:
                  </b-col>
                  <b-col sm="9">
                    0728-302440
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Email:
                  </b-col>
                  <b-col sm="9">
                    rma@lazarcomputers.ro
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <h6 class="mt-3">Bancare</h6>
            <b-row>
              <b-col xl="12">
                <b-row class="font-size-sm">
                  <b-col sm="3" class="text-primary">
                    Capital social:
                  </b-col>
                  <b-col sm="9">
                    52500 Lei
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    CUI:
                  </b-col>
                  <b-col sm="9">
                    RO7851977
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Nr. Reg. Com.:
                  </b-col>
                  <b-col sm="9">
                    J19/565/1995
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Banca:
                  </b-col>
                  <b-col sm="9">
                    BCR
                  </b-col>
                  <b-col sm="3" class="text-primary">
                    Cont:
                  </b-col>
                  <b-col sm="9">
                    RO05 RNCB 0155 0163 1223 0001
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <b-col xl="6">
          <base-block title="Info client" header-bg content-full>
            <b-row>
              <b-col xl="12">
                <b-row class="font-size-sm">
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.name" sm="3" class="text-primary">
                    Denumire
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.name" sm="9">
                    {{ meUser.companyHeadquarter.name }}
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.country" sm="3" class="text-primary">
                    Tara
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.country" sm="9">
                    {{ meUser.companyHeadquarter.country }}
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.county" sm="3" class="text-primary">
                    Judet
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.county" sm="9">
                    {{ meUser.companyHeadquarter.county }}
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.locality" sm="3" class="text-primary">
                    Localitate
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.locality" sm="9">
                    {{ meUser.companyHeadquarter.locality }}
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.fisdalCode" sm="3" class="text-primary">
                    CUI
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.fiscalCode" sm="9">
                    {{ meUser.companyHeadquarter.fiscalCode }}
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.registrationNumber" sm="3" class="text-primary">
                    Numarul de inregistrare
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.registrationNumber" sm="9">
                    {{ meUser.companyHeadquarter.registrationNumber }}
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.phone" sm="3" class="text-primary">
                    Numar de telefon
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.phone" sm="9">
                    {{ meUser.companyHeadquarter.phone }}
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter.email" sm="3" class="text-primary">
                    Adresa de mail
                  </b-col>
                  <b-col v-if="meUser != null && meUser.companyHeadquarter" sm="9">
                    {{ meUser.companyHeadquarter.email }}
                  </b-col>
                  <b-col v-if="meUser.company.term > 0" sm="3" class="text-primary">
                    Termen de plata:
                  </b-col>
                  <b-col v-if="meUser.company.term > 0" sm="9">
                    {{ meUser.company.term }} Zile
                  </b-col>
                  <b-col  v-if="meUser.company.credit > 0" sm="3" class="text-primary">
                    Limita creditare:
                  </b-col>
                  <b-col v-if="meUser.company.credit > 0" sm="9">
                    {{ meUser.company.credit }} Lei
                  </b-col>
                  <b-col v-if="meUser.company.discount > 0" sm="3" class="text-primary">
                    Discount general:
                  </b-col>
                  <b-col v-if="meUser.company.discount > 0" sm="9">
                    {{ meUser.company.discount }} %
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {CLIENT_USEFUL_INFORMATION} from "@/constants/client-data-sheet-graphql";

export default {
  name: "UsefulInformation",
  data () {
    return {
      meUser: null
    }
  },
  apollo: {
    meUser: {
      query: CLIENT_USEFUL_INFORMATION,
      fetchPolicy: "no-cache",
      result(result) {
        console.log(result);
      }
    }
  }
}
</script>

<style scoped>

</style>