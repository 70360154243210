import gql from 'graphql-tag';

export const GET_CLIENT_DATA_SHEET = gql`
query ClientDataSheet($first: Int, $after: String, $from: String, $to: String){
  meUser {
    clientDataSheet(first: $first, after: $after, filter_fromDate: $from, filter_toDate: $to) {
      pageInfo {
        hasNextPage,
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
      edges {
        node {
          id
          type
          series
          number
          date
          termDate
          invoiced
          payed
          delegate
          awb
          balance
        }
      }
    }
  }
}
`

export const PRINT_CLIENT_DATA_SHEET = gql`
mutation PrintClientDataSheet($from: String, $to: String){
  createPrintClientDataSheet(input: {
    fromDate: $from,
    toDate: $to
  }) {
    printClientDataSheet {
      response
    }
  }
}
`

export const CLIENT_USEFUL_INFORMATION = gql`
query ClientUsefulInformation{
  meUser {
    company {
     term
     credit
     discount
    }
    companyHeadquarter {
     name
     county
     locality
     country
     address
     phone
     email
   }
  }
}
`